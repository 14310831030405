import React, { useState } from 'react'
import brainteaser from '../Assets/brainteaser.webp'
import chevron from '../Assets/chevron.svg'

export default function Services() {

  const [open, setOpen] = useState("")

  const handleClick = (accordion) => {
    if(open === accordion){
      setOpen("")
    } else {
      setOpen(accordion)
    }
  }

  const handleClickScroll = () => {
    const element = document.getElementById('contact');
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };
  
  return (
    <div id='services'>

      <img id='brainteaser' src={brainteaser} alt="Laura Mellor web designer and branding work"/>

      <div id='services-right-container' style={{width: 800}}>
        <h2>Design Services</h2>
        <p style={{width: 650, maxWidth: '90vw'}}>I offer a range of different packages tailored to your requirements and budget</p>

        <div style={{margin: '50px 0'}}>
          <div className='service-container' onClick={() => handleClick("Branding")}>
            <div  style={{display:'flex', justifyContent:'space-between', alignItems:'center'}}>
              <h3>Visual Branding</h3>
              <img className={open === "Branding" ? 'chevron-inverted' : "chevron"} src={chevron}/>
            </div>
            <div className={open === "Branding" ? 'accordion-open' : 'accordion-closed'}>
              <p>
                Elevate your brand with a visual identity that represents your business and leaves a lasting impression on your audience.
              </p>
            </div>
          </div>

          <div className='service-container' onClick={() => handleClick("Design")}>
            <div style={{display:'flex', justifyContent:'space-between', alignItems:'center'}}>
              <h3>Web Design</h3>
              <img className={open === "Design" ? 'chevron-inverted' : "chevron"} src={chevron}/>
            </div>
            <div className={open === "Design" ? 'accordion-open' : 'accordion-closed'}>
              <p>Partnering with experienced developers to transform your online presence with a bespoke website crafted to engage visitors and reflect the essence of your brand. </p>
            </div>
          </div>

          <div className='service-container' onClick={() => handleClick("Print")}>
            <div style={{display:'flex', justifyContent:'space-between', alignItems:'center'}}>
              <h3>{window.innerWidth < 750 ? "Print & Packaging" : "Print Collateral & Packaging"}</h3>
              <img className={open === "Print" ? 'chevron-inverted' : "chevron"} src={chevron}/>
            </div>
            <div className={open === "Print" ? 'accordion-open' : 'accordion-closed'}>
              <p>Make a memorable impact on your customers with carefully designed packaging and stationery that enhances product presentation and reinforces your brand's personality.</p>
            </div>
          </div>

          <div className='service-container' onClick={() => handleClick("Illustration")}>
            <div style={{display:'flex', justifyContent:'space-between', alignItems:'center'}}>
              <h3>Illustration</h3>
              <img className={open === "Illustration" ? 'chevron-inverted' : "chevron"} src={chevron}/>
            </div>
            <div className={open === "Illustration" ? 'accordion-open' : 'accordion-closed'}>
              <p>Bring your brand narrative to life through custom illustrations that help to convey your story and add an artistic touch to your marketing materials.</p>
            </div>
          </div>


        </div>

        <button className='laura-btn' onClick={handleClickScroll}>
          Get In Touch
        </button>
      </div>
      
    </div>
  )
}
