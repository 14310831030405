import React from 'react'
import logo3 from '../Assets/LOGO-03.png'
import fb from '../Assets/fb.png'
import instagram from '../Assets/instagram.png'
import linkedin from '../Assets/linkedin.png'
import pinterest from '../Assets/pinterest.png'
import frantech from '../Assets/frantech.png'

export default function Footer() {
  return (
    <footer>
      <img id='logo-3' src={logo3}/>

      <div style={{display: 'flex', flexDirection: 'column', justifyContent:'center', alignItems:'center', maxWidth: '95vw'}}>
        <div style={{display: 'flex', flexWrap:'wrap'}}>
          <a href='https://www.facebook.com/profile.php?id=61556347603841' target='_blank'>
            <img src={fb} className='social' alt='Laura Mellor web designer social media icon' />
          </a>
          <a href='https://pinterest.co.uk/LauraMellor_Design' target='_blank'>
            <img src={pinterest} className='social' alt='Laura Mellor web designer social media icon' />
          </a>
          <a href='https://instagram.com/lauramellordesign' target='_blank'>
            <img src={instagram} className='social' alt='Laura Mellor web designer social media icon' />
          </a>
          <a href='https://linkedin.com/in/laura-mellor' target='_blank'>
            <img src={linkedin} className='social' alt='Laura Mellor web designer social media icon' />
          </a>
      
        </div>

        <a>info@lauramellor.co.uk</a>

      </div>

      <a href='https://frantech.org.uk' target='_blank' style={{textAlign:'center'}}>
        <p>Website by</p>
        <img src={frantech} id='frantech' alt='Frantech web solutions logo for web development' />
      </a>
    </footer>
  )
}
