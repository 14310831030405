import React, { useEffect} from 'react'

export default function Hero() {
  useEffect(() => {
    const handleScroll = () => {
      const hero = document.getElementById('hero-img');
      const scrollPosition = window.scrollY;

      // Calculate the distance to move the background based on the scroll position
      const movement = scrollPosition * 0.5;

       // Apply the background position with ease-in-out timing function
      hero.style.transition = 'background-position 0.1s ease';
      hero.style.backgroundPosition = `-${movement}px 0`;
    };

    // Attach the event listener when the component mounts
    window.addEventListener('scroll', handleScroll);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  return (
    <div id='hero'>
      <div id='hero-img'></div>
    </div>
  )
}
