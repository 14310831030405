import React from 'react'

export default function WorkWithMe() {

  const handleClickScroll = () => {
    const element = document.getElementById('contact');
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div id='work-with-me'>
      <h2>Work With Me</h2>

      <p>A strong brand identity is the cornerstone of any successful business. I specialise in crafting unique and compelling
        brand identities that resonate with audiences and set your business apart from the competition.
      </p>

      <button className='laura-btn' onClick={handleClickScroll}>Get In Touch</button>
    </div>
  )
}
