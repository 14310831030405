import React from 'react'
import img1 from '../Assets/gallery1.webp'
import img2 from '../Assets/gallery2.webp'
import img3 from '../Assets/gallery3.webp'
import img4 from '../Assets/gallery4.webp'
import img5 from '../Assets/gallery5.webp'
import img6 from '../Assets/gallery6.webp'

export default function Gallery() {
  return (
    <div id='gallery'>
      <img className='width-30' src={img1} alt="Laura Mellor Web Design Cheshire Image" onClick={() =>  window.open('https://fionawilsonprints.co.uk/', '_blank')}/>
      <img style={{paddingLeft: 15, paddingRight: 15}} className='width-40' src={img2} alt="Laura Mellor Web Design Cheshire Image" onClick={() =>  window.open('https://middlechildprints.co.uk/', '_blank')} />
      <img className='width-25' src={img3} alt="Laura Mellor Web Design Cheshire Image"/>
      <img style={{paddingTop: 15}} className='width-40' src={img4} alt="Laura Mellor Web Design Cheshire Image" onClick={() =>  window.open('https://gathercheshire.co.uk', '_blank')}/>
      <img style={{paddingLeft: 15, paddingTop: 15, paddingRight: 15}} className='width-25' src={img5} alt="Laura Mellor Web Design Cheshire Image"/>
      <img style={{paddingTop: 15}} className='width-30' src={img6} alt="Laura Mellor Web Design Cheshire Image"/>

    </div>
  )
}
