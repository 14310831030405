import React, { useState } from 'react'
import logo1 from '../Assets/LOGO-01.png'
import logo2 from '../Assets/LOGO-02.png'

export default function Navbar({setNavOpen, navOpen}) {


  const handleClickScroll = (id) => {
    const element = document.getElementById(id);
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <>
    <header>
        <img id='initial-logo' src={logo2}/>
        <img id='logo-name' src={logo1}/>

        <nav>
            <a onClick={() => handleClickScroll('services')}>Services</a>
            <a onClick={() => handleClickScroll('gallery')}>Work</a>
            <a onClick={() => handleClickScroll('contact')}>Contact</a>
        </nav>


      {/*Mobile view */}
        <div id='burger-menu' className={navOpen ? 'open' : ''} onClick={() => setNavOpen(!navOpen)}>
          <div id='burger-top'/>
          <div id='burger-bottom'/>
        </div>

      
        
        
    </header>

    </>
  )
}
