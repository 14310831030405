import React, { useState } from 'react'
import './App.css';
import Navbar from './Components/Navbar';
import Hero from './Components/Hero';
import Services from './Components/Services';
import WorkWithMe from './Components/WorkWithMe';
import Gallery from './Components/Gallery';
import Contact from './Components/Contact';
import Footer from './Components/Footer';
import middleChild from './Assets/gallery2.webp'
import kid from './Assets/gallery6.webp'
import gather from './Assets/gallery4.webp'
import menu from './Assets/gallery3.webp'
import Sidenav from './Components/Sidenav';

function App() {
  const [navOpen, setNavOpen] = useState(false)

  const handleClickScroll = () => {
    const element = document.getElementById('contact');
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div className="App">
      <Sidenav navOpen={navOpen} setNavOpen={setNavOpen}/>
      <Navbar navOpen={navOpen} setNavOpen={setNavOpen}/>
      <div id='elevate-mobile'>
        <p >Elevate your business with a brand identity that sets you apart.</p>
        <button className='laura-btn' onClick={handleClickScroll}>Work With Me</button>
      </div>
      <Hero />
      <div id='elevate'>
        <p>Elevate your business with a thoughtfully crafted brand identity that sets you apart.</p>
        <button className='laura-btn' onClick={handleClickScroll}>Work With Me</button>
      </div>
      <Services />
      <img className='mobile-img' src={middleChild} alt='Laura Mellor Web Design work'/>
      <WorkWithMe />
      <img id='mobile-gallery' className='mobile-img' src={kid} alt='Laura Mellor Web Design work'/>
      <img className='mobile-img' src={gather} alt='Laura Mellor Web Design work'/>
      <img className='mobile-img' src={menu} alt='Laura Mellor Web Design work'/>
      <Gallery />
      <Contact />
      <Footer />
    </div>
  );
}

export default App;
