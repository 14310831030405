import React, { useState } from 'react'
import axios from 'axios'

export default function Contact() {

  const [name, setName] = useState()
  const [email, setEmail] = useState()
  const [msg, setMsg] = useState()
  const [sent, setSent] = useState(false)

  const handleContact = (e) => {
    e.preventDefault()

    if(!name || !email || !msg){
      alert("Please add all fields")

    } else {
      axios.post(`${process.env.REACT_APP_API_URL}/laura/contact`, {name, email, msg})
      .then(() => {
        setSent(true)
      })
      .catch((e) => {
        console.log(e)
      })

    }
  }

  return (
    <div id='contact'>
      <h2>Get In Touch</h2>

      <p>
        If you are interested in working with me I would love to hear from you.
        Please fill out the form and I will get in touch with you soon. 
      </p>

      {sent ?
      <h3 id='sent-msg'>Successfully sent!</h3>

      :
      <form>
        <label>Name</label>
        <input type='text' value={name} onChange={(e) => setName(e.target.value)}/>

        <label>Email</label>
        <input type='email' value={email} onChange={(e) => setEmail(e.target.value)}/>

        <label>{window.innerWidth < 750 ? "Please describe your project" : "Please describe your project, including any deadlines"}</label>
        <textarea value={msg} onChange={(e) => setMsg(e.target.value)}/>

        <button  style={{margin: '0 auto'}} className='laura-btn' onClick={handleContact}>Send</button>
      </form>
      }

      
    </div>
  )
}
