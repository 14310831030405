import React from 'react'
import fb from '../Assets/fb.png'
import instagram from '../Assets/instagram.png'
import linkedin from '../Assets/linkedin.png'
import pinterest from '../Assets/pinterest.png'

export default function Sidenav({navOpen, setNavOpen}) {

        const handleClickScroll = (id) => {
            const element = document.getElementById(id);
            if (element) {
              // 👇 Will scroll smoothly to the top of the next section
              element.scrollIntoView({ behavior: 'smooth' });
            }

            setNavOpen(false)

          };

  return (
    <nav className={navOpen ? 'sidenav-open' : 'sidenav'}>
        <div></div>

        <div style={{display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center'}}>
            <a className='sidenav-link' onClick={() => handleClickScroll('services')}>Services</a>
            <a className='sidenav-link' onClick={() => handleClickScroll('mobile-gallery')}>Work</a>
            <a className='sidenav-link' onClick={() => handleClickScroll('contact')}>Contact</a>
        </div>
        
        <div style={{display: 'flex', flexDirection: 'column', justifyContent:'center', alignItems:'center', maxWidth: '95vw', paddingBottom: 70}}>
        <div style={{display: 'flex'}}>
          <a href='https://www.facebook.com/profile.php?id=61556347603841' target='_blank'>
            <img src={fb} className='social' alt='Laura Mellor web designer social media icon' />
          </a>
          <a href='https://pinterest.co.uk/LauraMellor_Design' target='_blank'>
            <img src={pinterest} className='social' alt='Laura Mellor web designer social media icon' />
          </a>
          <a href='https://instagram.com/lauramellordesign' target='_blank'>
            <img src={instagram} className='social' alt='Laura Mellor web designer social media icon' />
          </a>
          <a href='https://linkedin.com/in/laura-mellor' target='_blank'>
            <img src={linkedin} className='social' alt='Laura Mellor web designer social media icon' />
          </a>
      
        </div>

        <a>info@lauramellor.co.uk</a>

      </div>

    </nav>
  )
}
